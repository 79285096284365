@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-mgpBlack text-mgpWhite
}

a {
    @apply text-blue-400
}